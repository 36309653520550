import React, { useEffect, useState, useRef } from 'react';
import useForm from '../hooks/useForm';
import BellService from "../services/BellService";
import { useCreditRequest } from '../Context/useCreditRequest';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import AuthSoraService from '../services/AuthSoraService';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import CustomBox from "src/components/CustomBox";
import '../StyleSheets/SwalStyle.css';
import Iconify from '../components/iconify';
import { useGlobalProvider } from '../components/GlobalProvider'
import TarsServices from '../services/TarsService';


const validateForm = (form) => {
    let errors = {};

    if (!form.digit1.trim()) { errors.digit1 = "El campo digit1 es requerido"; }
    if (!form.digit2.trim()) { errors.digit2 = "El campo digit2 es requerido"; }
    if (!form.digit3.trim()) { errors.digit3 = "El campo digit3 es requerido"; }
    if (!form.digit4.trim()) { errors.digit4 = "El campo digit4 es requerido"; }
    if (!form.digit5.trim()) { errors.digit5 = "El campo digit5 es requerido"; }
    if (!form.digit6.trim()) { errors.digit6 = "El campo digit6 es requerido"; }

    return errors;
};

const NewCustomerS3_2 = ({ handleChangeStep, handleCloseModal }) => {
    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request")
    const initialForm = { digit1: "", digit2: "", digit3: "", digit4: "", digit5: "", digit6: "" };
    const [sending, setSending] = useState(false);
    const { form, errors, handleChangeOtp, handleSubmit, resetForm } = useForm(initialForm, validateForm);
    const { setLogoSize, resendTimeSMS, setResendTimeSMS, resendTimeEmail, setResendTimeEmail, resendTimeWA, setResendTimeWA } = useCreditRequest();
    const formRef = useRef(null);
    const inputReference = useRef(null);
    const [txtHeader, setTxtHeader] = useState("");
    // const [isEmailOTP, setIsEmailOTP] = useState(request.OTP === 1);

    const {
        GenerateOTPAndSendMessage,
        GenerateOTPAndSendMessageEmail,
        ValidateOTPAndCreateCustomer,
        ValidateEMailOTPAndCreateCustomer,
        GenerateOTPAndSendMessageWhatsApp,
        ValidateWhatsAppOTPAndCreateCustomer
    } = BellService();
    const { InfolaftConsult } = TarsServices();
    const MySwal = withReactContent(Swal);
    const { GenerateOTPMethod, validateOTPMethod } = AuthSoraService();
    const { posData } = useGlobalProvider();

    const handlePasteAnywhere = event => {
        let text = event.clipboardData.getData('text');

        if (text.length === 6 && !isNaN(text)) {

            handleChangeOtp({ target: { name: "digit1", value: text[0] } })
            handleChangeOtp({ target: { name: "digit2", value: text[1] } })
            handleChangeOtp({ target: { name: "digit3", value: text[2] } })
            handleChangeOtp({ target: { name: "digit4", value: text[3] } })
            handleChangeOtp({ target: { name: "digit5", value: text[4] } })
            handleChangeOtp({ target: { name: "digit6", value: text[5] } })
        }

    };

    useEffect(() => {
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" })

        inputReference.current.focus();

        window.addEventListener('paste', handlePasteAnywhere);

        if (request.origin === 1) {
            if (request.OTP === 1) {
                setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                emailOTP();
            }
            else if (request.OTP === 2) {
                setTxtHeader(`Le hemos enviado un mensaje de texto al número <b>${request.phoneCodeTemp + " " + request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                smsOTP();
            }
            else if (request.OTP === 3) {
                setTxtHeader(`Le hemos enviado un mensaje de WhatsApp al número <b>${request.phoneCodeTemp + " " + request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                waOTP();
            }
        }
        else {
            if (request.OTP === 1) {
                setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)

                OTPMethodEmail();
            }
            else if (request.OTP === 2) {
                setTxtHeader(`Le hemos enviado un mensaje de texto al número <b>${request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)

                OTPMethodSMS();
            }
            else if (request.OTP === 3) {
                setTxtHeader(`Le hemos enviado un mensaje de WhatsApp al número <b>${request.cusPhone}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                OTPMethodWA();
            }
        }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => () => {
        window.removeEventListener('paste', handlePasteAnywhere);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const smsOTP = () => {

        if (resendTimeSMS > 0) {

            return;
        }

        setSending(true);

        let body =
        {
            destination: request.phoneCodeTemp + request.cusPhone,
            region: "co-co",
            lang: "co",
            idPointOfSell: posData.posId,
            posCommercialName: posData.posCommercialName,
        }

        GenerateOTPAndSendMessage(body).then(res => {

            setSending(false);
            if (res.status !== 200) {
                MySwal.fire({
                    html: "Se generó un error al enviar el OTP",
                    icon: 'error',
                    customClass: {
                        container: 'swal2-container'
                    }
                })
                return;
            }
            // setIsEmailOTP(false);

            setResendTimeSMS(60);

            var timer = setInterval(() => {

                setResendTimeSMS(t => {
                    const modifiedValue = t - 1;
                    if (modifiedValue <= 0) {
                        clearInterval(timer);
                        setResendTimeSMS(null);
                    }
                    return modifiedValue;
                });


            }, 1000);


        });
    }

    const emailOTP = () => {

        if (resendTimeEmail > 0) {

            return;
        }

        setSending(true);
        let body =
        {
            destination: request.cusEmail,
            region: "co-co",
            lang: "co",
            idPointOfSell: posData.posId,
            posCommercialName: posData.posCommercialName,
        }

        GenerateOTPAndSendMessageEmail(body).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                // setIsEmailOTP(true);

                setResendTimeEmail(60);

                var timer = setInterval(() => {

                    setResendTimeEmail(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeEmail(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Se generó un error al enviar el OTP",
                    icon: 'error',
                    customClass: {
                        container: 'swal2-container'
                    }
                })
            }

        })
    }

    const waOTP = () => {

        if (resendTimeWA > 0) {

            return;
        }

        setSending(true);

        let body =
        {
            destination: request.phoneCodeTemp + request.cusPhone,
            region: "co-co",
            lang: "co",
            idPointOfSell: posData.posId,
            posCommercialName: posData.posCommercialName,
        }

        GenerateOTPAndSendMessageWhatsApp(body).then(res => {

            setSending(false);
            if (res.status !== 200) {
                MySwal.fire({
                    html: "Se generó un error al enviar el OTP",
                    icon: 'error',
                    customClass: {
                        container: 'swal2-container'
                    }
                })
                return;
            }
            // setIsEmailOTP(false);

            setResendTimeWA(60);

            var timer = setInterval(() => {

                setResendTimeWA(t => {
                    const modifiedValue = t - 1;
                    if (modifiedValue <= 0) {
                        clearInterval(timer);
                        setResendTimeWA(null);
                    }
                    return modifiedValue;
                });


            }, 1000);


        });
    }

    const OTPMethodEmail = () => {

        if (resendTimeEmail > 0) {

            return;
        }

        setSending(true);


        GenerateOTPMethod(request.idMethodEmail).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                // setIsEmailOTP(true);

                setResendTimeEmail(60);

                var timer = setInterval(() => {

                    setResendTimeEmail(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeEmail(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Error al enviar Codigo al correo",
                    icon: 'error',
                    customClass: {
                        container: 'swal2-container'
                    }
                })
            }

        })
    }

    const OTPMethodSMS = () => {

        if (resendTimeSMS > 0) {

            return;
        }

        setSending(true);


        GenerateOTPMethod(request.idMethodSMS).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                //setIsEmailOTP(false);

                setResendTimeSMS(60);

                var timer = setInterval(() => {

                    setResendTimeSMS(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeSMS(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Error al enviar Codigo al SMS",
                    icon: 'error',
                    customClass: {
                        container: 'swal2-container'
                    }
                })
            }

        })
    }

    const OTPMethodWA = () => {

        if (resendTimeWA > 0) {

            return;
        }

        setSending(true);


        GenerateOTPMethod(request.idMethodWA).then(res => {

            setSending(false);
            if (res.status === 200) {

                //setTxtHeader(`Le hemos enviado un mensaje al correo <b>${request.cusEmail}</b> que contiene un código numérico de 6 digitos, por favor, introduzcalo a continuación.`)
                //setIsEmailOTP(false);

                setResendTimeWA(60);

                var timer = setInterval(() => {

                    setResendTimeWA(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTimeWA(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            }
            else {
                MySwal.fire({
                    html: "Error al enviar Codigo al Whastapp",
                    icon: 'error',
                    customClass: {
                        container: 'swal2-container'
                    }
                })
            }

        })
    }

    useEffect(() => {
        let OTP = form.digit1 + form.digit2 + form.digit3 + form.digit4 + form.digit5 + form.digit6;

        if (OTP.length === 6) {
            formRef.current.click();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.digit6]);

    const submitForm = (form, error) => {

        if (!error) {
            setSending(true);

            let OTP = form.digit1 + form.digit2 + form.digit3 + form.digit4 + form.digit5 + form.digit6;

            if (request.origin === 1) {
                if (request.OTP === 1) {
                    ValidateEMailOTPAndCreateCustomer({
                        destination: request.cusEmail,
                        codeOTP: OTP,
                        region: "co-co",
                        lang: "co",


                        cusType: request.cusType ?? "REGP",
                        cusDocumentType: request.cusDocumentType,
                        cusDocument: request.cusDocument,
                        cusFirstName: request.cusFirstName,
                        cusSecondName: request.cusSecondName,
                        cusFirstSurname: request.cusFirstSurname,
                        cusSecondSurname: request.cusSecondSurname,
                        cusGender: request.cusGender,
                        cusBirthDate: `${request.year}-${('00' + request.month).slice(-2)}-${('00' + request.day).slice(-2)}`,
                        cusNationality: request.cusNationality,
                        cusBirthCountry: request.cusBirthCountry,
                        cusEmail: request.cusEmail,
                        cusPhoneCountry: request.cusPhoneCountry,
                        cusPhone: request.cusPhone,
                        cusConditionsAcceptance: true,
                        idPos: posData.posId
                    }).then(res => {
                     
                        setSending(false);
                        if (res.status !== 200) {

                            resetForm(initialForm);
                            // inputReference.current.focus();
                            MySwal.fire({
                                html: res.data.message,
                                icon: 'error',
                                customClass: {
                                    container: 'swal2-container'
                                }
                            })
                            return;
                        }

                        const fullName = 
                            request.cusFirstName + 
                            (request.cusSecondName !== "" ? " " + request.cusSecondName : "") +
                            (request.cusFirstSurname !== "" ? " " + request.cusFirstSurname : "") +
                            (request.cusSecondSurname !== "" ? " " + request.cusSecondSurname : "");

                        InfolaftConsult(request.cusDocument, fullName).then(res => { })

                        let resDataOTP = JSON.parse(res.data.data)
                        setStorage("request", {
                            ...request,
                            phoneValidated: true,
                            IdCustomer: resDataOTP.IdCustomer,
                            state: 4
                        });
                        handleChangeStep(4);
                    });
                }
                else if (request.OTP === 2) {
                    ValidateOTPAndCreateCustomer({
                        destination: request.phoneCodeTemp + request.cusPhone,
                        codeOTP: OTP,
                        region: "co-co",
                        lang: "co",

                        cusType: request.cusType ?? "REGP",
                        cusDocumentType: request.cusDocumentType,
                        cusDocument: request.cusDocument,
                        cusFirstName: request.cusFirstName,
                        cusSecondName: request.cusSecondName,
                        cusFirstSurname: request.cusFirstSurname,
                        cusSecondSurname: request.cusSecondSurname,
                        cusGender: request.cusGender,
                        cusBirthDate: `${request.year}-${('00' + request.month).slice(-2)}-${('00' + request.day).slice(-2)}`,
                        cusNationality: request.cusNationality,
                        cusBirthCountry: request.cusBirthCountry,
                        cusEmail: request.cusEmail,
                        cusPhoneCountry: request.cusPhoneCountry,
                        cusPhone: request.cusPhone,
                        cusConditionsAcceptance: true,
                        idPos: posData.posId
                    }).then(res => {
                    
                        setSending(false);
                        if (res.status !== 200) {
                            resetForm(initialForm);
                            // inputReference.current.focus();
                            MySwal.fire({
                                html: res.data.message,
                                icon: 'error',
                                customClass: {
                                    container: 'swal2-container'
                                }
                            })

                            return;
                        }

                        const fullName = 
                            request.cusFirstName + 
                            (request.cusSecondName !== "" ? " " + request.cusSecondName : "") +
                            (request.cusFirstSurname !== "" ? " " + request.cusFirstSurname : "") +
                            (request.cusSecondSurname !== "" ? " " + request.cusSecondSurname : "");

                        InfolaftConsult(request.cusDocument, fullName).then(res => { })

                        let resDataOTP = JSON.parse(res.data.data)
                        setStorage("request", {
                            ...request,
                            phoneValidated: true,
                            IdCustomer: resDataOTP.IdCustomer,
                            state: 4
                        });

                        handleChangeStep(4);
                    });
                }

                else if (request.OTP === 3) {
                    ValidateWhatsAppOTPAndCreateCustomer({
                        destination: request.phoneCodeTemp + request.cusPhone,
                        codeOTP: OTP,
                        region: "co-co",
                        lang: "co",


                        cusType: request.cusType ?? "REGP",
                        cusDocumentType: request.cusDocumentType,
                        cusDocument: request.cusDocument,
                        cusFirstName: request.cusFirstName,
                        cusSecondName: request.cusSecondName,
                        cusFirstSurname: request.cusFirstSurname,
                        cusSecondSurname: request.cusSecondSurname,
                        cusGender: request.cusGender,
                        cusBirthDate: `${request.year}-${('00' + request.month).slice(-2)}-${('00' + request.day).slice(-2)}`,
                        cusNationality: request.cusNationality,
                        cusBirthCountry: request.cusBirthCountry,
                        cusEmail: request.cusEmail,
                        cusPhoneCountry: request.cusPhoneCountry,
                        cusPhone: request.cusPhone,
                        cusConditionsAcceptance: true,
                        idPos: posData.posId
                    }).then(res => {
                       
                        setSending(false);
                        if (res.status !== 200) {
                            resetForm(initialForm);
                            // inputReference.current.focus();
                            MySwal.fire({
                                html: res.data.message,
                                icon: 'error',
                                customClass: {
                                    container: 'swal2-container'
                                }
                            })

                            return;
                        }

                        const fullName = 
                            request.cusFirstName + 
                            (request.cusSecondName !== "" ? " " + request.cusSecondName : "") +
                            (request.cusFirstSurname !== "" ? " " + request.cusFirstSurname : "") +
                            (request.cusSecondSurname !== "" ? " " + request.cusSecondSurname : "");

                        InfolaftConsult(request.cusDocument, fullName).then(res => { })

                        let resDataOTP = JSON.parse(res.data.data)
                        setStorage("request", {
                            ...request,
                            phoneValidated: true,
                            IdCustomer: resDataOTP.IdCustomer,
                            state: 4
                        });

                        handleChangeStep(4);
                    });
                }
            }
            else {
                validateOTPMethod(request.OTP === 1 ? request.idMethodEmail : request.OTP === 2 ? request.idMethodSMS : request.idMethodWA, OTP).then(res => {
                    setSending(false);
                    // console.log(res.data)
                    if (res.status !== 200) {
                        resetForm(initialForm);
                        inputReference.current.focus();
                        MySwal.fire({
                            html: res.data.message,
                            icon: 'error',
                            customClass: {
                                container: 'swal2-container'
                            }
                        })
                        return;
                    }

                    setStorage("creditRequestToken", JSON.parse(res.data.data).Token, { path: "/" })
                    setStorage("expiredTime", Date.parse(JSON.parse(res.data.data).ExpiredTime), { path: "/" })


                    setStorage("request", {
                        ...request,
                        phoneValidated: true,
                        state: 9
                    });

                    // close modal
                })
            }


        }


    }

    const handleResendCode = () => {
        if (request.origin === 1) {
            if (request.OTP === 1) {
                emailOTP();
            } else if (request.OTP === 2) {
                smsOTP();
            } else if (request.OTP === 3) {
                waOTP();
            }
        } else {
            if (request.OTP === 1) {
                OTPMethodEmail();
            } else if (request.OTP === 2) {
                OTPMethodSMS();
            } else if (request.OTP === 3) {
                OTPMethodWA();
            }
        }
    };

    const isResendDisabled = (request.OTP === 1 ? resendTimeEmail : request.OTP === 2 ? resendTimeSMS : resendTimeWA) > 0;

    const modalStyle = {
        margin: "50px auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <CustomBox
            sx={{
                width: '80%'
            }}
        >
            <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right", marginTop: "0px", paddingTop: "0px" }}>
                <button style={{ border: "none", backgroundColor: "transparent", fontWeight: "bold", fontSize: "30px", cursor: "pointer", marginTop: "0px" }} onClick={handleCloseModal} ><Iconify sx={{ width: "30px", height: "30px" }} icon="eva:close-outline" /></button>

            </Grid>

            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <Box sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, margin: '0 auto', textAlign: 'center' }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="black" sx={{ fontSize: '13px' }}>
                                {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="gray" sx={{ fontSize: '13px' }}>
                                {request.cusDocumentTypeTemp} {request.cusDocument}
                            </Typography>
                        </Grid>
                        {request.origin === 2 ? (
                            <Grid item xs={12}><br /></Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Typography variant="h5" color="gray">
                                    <b style={{ color: "black" }}>2</b>/4
                                </Typography>
                            </Grid>
                        )}
                        {!sending && <> <Grid item xs={12}>
                            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: txtHeader }} />
                        </Grid>
                            <Grid item xs={12} style={{ padding: "16px 0px 16px 0px" }}>
                                {/* Inputs para OTP */}
                                <Grid container spacing={1} justifyContent="center" >
                                    {[...Array(6)].map((_, index) => (
                                        <Grid item key={index}>
                                            <TextField
                                                inputRef={index === 0 ? inputReference : null}
                                                name={`digit${index + 1}`}
                                                type="number"
                                                value={form[`digit${index + 1}`]}
                                                onChange={handleChangeOtp}
                                                inputProps={{
                                                    maxLength: 1,
                                                    pattern: "[0-9]*",
                                                    style: { textAlign: 'center' },
                                                }}
                                                sx={{
                                                    width: { xs: '40px', sm: '50px' },
                                                    fontWeight: 'bold',
                                                }}
                                                error={!!errors[`digit${index + 1}`]}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            {/* Enlace para reenviar código */}
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: isResendDisabled || sending ? "gray" : "blue",
                                        cursor: isResendDisabled || sending ? "not-allowed" : "pointer",
                                        textDecoration: 'underline',
                                    }}
                                    onClick={!isResendDisabled ? handleResendCode : undefined}
                                >
                                    {request.OTP === 1 ? "Reenviar código al correo" : request.OTP === 2 ? "Reenviar código al celular" : "Reenviar código al WhatsApp"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    {isResendDisabled && `Puedes reenviar el código en ${request.OTP === 1 ? resendTimeEmail : request.OTP === 2 ? resendTimeSMS : resendTimeWA} segundos`}
                                </Typography>
                            </Grid>
                            {/* Botones */}
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    disabled={sending}
                                    sx={{ marginRight: 2 }}
                                    onClick={() => {
                                        setStorage("request", {
                                            ...request, state: 2
                                        });
                                        handleChangeStep(2);
                                    }}
                                >
                                    Regresar
                                </Button>
                                <Button type="submit" variant="contained" color="primary" disabled={sending} ref={formRef}>
                                    Continuar
                                </Button>
                            </Grid></>}
                        {/* Loader */}
                        {sending && (
                            <Grid item xs={12}>
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </form >
        </CustomBox >
    );
};

export default NewCustomerS3_2;
